// App.jsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import CinemaMap from './components/Map';
import { Loading, Error } from './components/Loading';
import { useCinemas } from './hooks/useCinemas';
import { useMovies } from './hooks/useMovies';
import { Star, Calendar, Check } from 'lucide-react';

const App = () => {
    const { cinemas, loading, error } = useCinemas();
    const { movies, loadingMovie, errorMovie } = useMovies();
    const [isMobile, setIsMobile] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [showingType, setShowingType] = useState('current'); // 'current' or 'retro'
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [filters, setFilters] = useState({
        current: true,
        retro: false
    });

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    if (loading || loadingMovie) return <Loading message="Chargement de la carte..." />;
    if (error || errorMovie) return <Error message={error || errorMovie} />;

    const validCinemas = cinemas.filter(cinema =>
        cinema.coordinates.lat != null && cinema.coordinates.lon != null
    );

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <Star
                    key={i}
                    size={16}
                    className={`${
                        i < rating
                            ? 'text-yellow-400 fill-yellow-400'
                            : 'text-gray-300'
                    }`}
                />
            );
        }
        return stars;
    };

    const CheckableButton = ({ checked, onClick, children }) => (
        <button
            onClick={onClick}
            className={`
        px-3 py-1.5 text-sm rounded-full flex items-center gap-2
        ${checked
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 text-gray-6'
            }
      `}
        >
            <div className={`
        w-4 h-4 rounded flex items-center justify-center
        ${checked ? 'bg-white' : 'bg-white border border-gray-400'}
      `}>
                {checked && <Check size={12} className="text-blue-500" />}
            </div>
            {children}
        </button>
    );

    const toggleFilter = (filterType) => {
        setFilters(prev => ({
            ...prev,
            [filterType]: !prev[filterType]
        }));
    };

    const filteredMovies = movies.filter(movie =>
        (filters[movie.type]) && movie.date === selectedDate
    );


    return (
        <div style={{
            position: 'relative',
            height: '100vh',
            width: '100%'
        }}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }}>
                <CinemaMap cinemas={validCinemas} />
            </div>

            {isMobile && (
                <motion.div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: '100vh',
                        backgroundColor: 'white',
                        boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)',
                        zIndex: 9999,
                        pointerEvents: 'auto'
                    }}
                    drag="y"
                    dragConstraints={{ top: 0, bottom: 0 }}
                    dragElastic={0.2}
                    initial={{ y: "50%" }}
                    animate={{ y: isDrawerOpen ? "0%" : "50%" }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    onDragEnd={(_, info) => {
                        if (info.offset.y < -50) {
                            setIsDrawerOpen(true);
                        } else if (info.offset.y > 50) {
                            setIsDrawerOpen(false);
                        }
                    }}
                >
                    <div style={{
                        height: '35px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'grab'
                    }}>
                        <div style={{
                            width: '48px',
                            height: '6px',
                            backgroundColor: '#E5E7EB',
                            borderRadius: '9999px'
                        }} />
                    </div>

                    <div style={{ padding: '0 5px' }}>
                        <h2 style={{
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            marginBottom: '1rem'
                        }} className="ps-2">
                            Projections du jour
                        </h2>
                        {/*<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            {movies.slice(0, 5).map(movie => (
                                <div key={movie.id} style={{ padding: '0.5rem' }}>
                                    <h3 style={{ fontWeight: '600' }}>{movie.title}</h3>
                                </div>
                            ))}
                        </div>*/}
                        <div className="w-full">
                            <div className="mb-4 space-y-3">
                                {/* Checkable Buttons */}
                                <div className="flex gap-2">
                                    <CheckableButton
                                        checked={filters.current}
                                        onClick={() => toggleFilter('current')}
                                    >
                                        Films à l'affiche
                                    </CheckableButton>
                                    <CheckableButton
                                        checked={filters.retro}
                                        onClick={() => toggleFilter('retro')}
                                    >
                                        Rétro-projections
                                    </CheckableButton>
                                </div>

                                {/* Date Picker */}
                                <div className="flex items-center gap-2 bg-gray-100 p-2 rounded w-fit">
                                    <Calendar size={16} className="text-gray-500" />
                                    <input
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        className="bg-transparent text-sm text-gray-600 outline-none"
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-2">
                                {movies.slice(0, 6).map(movie => (
                                    <div
                                        key={movie.id}
                                        className="bg-white rounded bg-gray-100 p-2"
                                    >
                                        <h3 className="text-sm font-semibold mb-1">{movie.title}</h3>

                                        <div className="space-y-1 text-xs text-gray-600">
                                            <div className="flex justify-between items-center">
                                                <span className="font-medium">Date:</span>
                                                <span>{new Date(movie.date).toLocaleDateString()}</span>
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <span className="font-medium">Director:</span>
                                                <span className="truncate max-w-[100px]">{movie.director}</span>
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <span className="font-medium">Time:</span>
                                                <span>{movie.showtime}</span>
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <span className="font-medium">Cinema:</span>
                                                <span className="truncate max-w-[100px]">{movie.cinema}</span>
                                            </div>

                                            <div className="flex justify-between items-center">
                                                <span className="font-medium">Rating:</span>
                                                <div className="flex gap-0.5">
                                                    {renderStars(movie.rating)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default App;